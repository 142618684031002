export const loadingHeroImage = {
    bannerId: '1',
    description: '',
    imageUri: '/webres/Image/banners/OGS/hero-white.jpg',
    mainHref: '',
    subtitle: '',
    title: 'Loading',
    type: 'HERO'
};

export const loadingSecondaryImageTop = {
    bannerId: '2',
    imageUri: '/webres/Image/banners/OGS/secondary-white.jpg',
    mainHref: '',
    title: 'Loading',
    type: 'SECONDARY_TOP'
};

export const loadingSecondaryImageMiddle = {
    bannerId: '3',
    imageUri: '/webres/Image/banners/OGS/secondary-white.jpg',
    mainHref: '',
    title: 'Loading',
    type: 'SECONDARY_MIDDLE'
};

export const loadingSecondaryImageBottom = {
    bannerId: '4',
    imageUri: '/webres/Image/banners/OGS/secondary-white.jpg',
    mainHref: '',
    title: 'Loading',
    type: 'SECONDARY_BOTTOM'
};
