import SwimlaneTypes from 'client/enums/swimlane-types';

export interface IFirestoreCallout {
    enabled: boolean;
    global?: boolean;
    important: boolean;
    heading: string;
    stores: number[];
    subText: string;
}

export interface IFeaturedCollectionPromo {
    enabled: boolean;
    heading: string;
    imageUrl: string;
    linkText: string;
    linkUrl: string;
    subText: string;
}

export interface IFirestoreFeaturedCollection {
    collectionId: number;
    promo?: IFeaturedCollectionPromo;
    enabled: boolean;
    slotId: number;
}

export interface IFirestoreHero {
    enabled: boolean;
    heading: string;
    images: {
        mobile: string;
        web: string;
    };
    link: string;
    subText: string;
}

export interface IFireStoreAd {
    href: string;
    id: number;
    images: {
        mobile: string;
        web: string;
    };
    prioritize?: boolean;
    title: string;
    enabled: boolean;
}

export interface ICmsLargeAd {
    enabled: boolean;
    href: string;
    id: string;
    imageUrl: string;
}

export enum CmsAdType {
    SLOT_1 = 'Slot_1',
    SLOT_2 = 'Slot_2',
    SLOT_3 = 'Slot_3',
    SLOT_4 = 'Slot_4',
    SLOT_5 = 'Slot_5',
    SLOT_6 = 'Slot_6',
    SLOT_7 = 'Slot_7',
    SLOT_8 = 'Slot_8'
}
export interface IFireStoreAds {
    [CmsAdType.SLOT_1]: IFireStoreAd[];
    [CmsAdType.SLOT_2]: IFireStoreAd[];
    [CmsAdType.SLOT_3]: IFireStoreAd[];
    [CmsAdType.SLOT_4]: IFireStoreAd[];
    [CmsAdType.SLOT_5]: IFireStoreAd[];
    [CmsAdType.SLOT_6]: IFireStoreAd[];
    [CmsAdType.SLOT_7]: IFireStoreAd[];
    [CmsAdType.SLOT_8]: IFireStoreAd[];
}

export interface ISwimlaneOrder {
    type: SwimlaneTypes;
    order: number;
}

export const defaultHomePageOrder: ISwimlaneOrder[] = [
    {
        order: 0,
        type: SwimlaneTypes.BUY_AGAIN
    },
    {
        order: 1,
        type: SwimlaneTypes.FEATURED_COLLECTION_1
    },
    {
        order: 2,
        type: SwimlaneTypes.FUEL_SAVER
    },
    {
        order: 3,
        type: SwimlaneTypes.FEATURED_COLLECTION_2
    },
    {
        order: 4,
        type: SwimlaneTypes.ON_SALE
    },
    {
        order: 5,
        type: SwimlaneTypes.FEATURED_COLLECTION_3
    },
    {
        order: 6,
        type: SwimlaneTypes.PREDICTIONS
    },
    {
        order: 7,
        type: SwimlaneTypes.FEATURED_COLLECTION_4
    },
    {
        order: 8,
        type: SwimlaneTypes.RECOMMENDATION
    },
    {
        order: 9,
        type: SwimlaneTypes.POPULAR_PRODUCTS
    }
];

export interface IHomePageCategory {
    linkUrl: string;
    name: string;
    imageUrl: string;
    order: number;
    enabled: boolean;
}

export interface IDietAndLifestyle {
    href: string;
    largeImageSrc: string;
    name: string;
    src: string;
    testId: string;
    order: number;
    enabled: boolean;
}
