import {fulfillmentDisplayTypes} from './view-helpers/fulfillment-view-helpers';

const defaultFulfillmentFee = {
    feeWithMembership: 0,
    feeWithoutMembership: 0,
    orderMinimumAmount: 0
};

const getMatchingFulfillmentFees = (fulfillmentFees, duration, priorityType) =>
    fulfillmentFees.filter(
        (fulfillmentFee) => fulfillmentFee.duration === duration && fulfillmentFee.priorityType === priorityType
    );

export const getFeeForTimeSlot = (fulfillmentFees, fulfillmentTime, cartTotal) => {
    const fulfillmentFeesWithCorrectDuration = getMatchingFulfillmentFees(
        fulfillmentFees,
        fulfillmentTime.duration,
        fulfillmentTime.priorityType
    );

    fulfillmentFeesWithCorrectDuration.sort(
        (firstFee, secondFee) => secondFee.orderMinimumAmount - firstFee.orderMinimumAmount
    );

    const feeForTimeSlot =
        fulfillmentFeesWithCorrectDuration.find((fulfillmentFee) => fulfillmentFee.orderMinimumAmount <= cartTotal) ||
        fulfillmentFeesWithCorrectDuration.pop();

    return feeForTimeSlot || defaultFulfillmentFee;
};

export const getThresholdFeeForTimeSlot = (fulfillmentFees, duration, cartTotal) => {
    const fulfillmentFeesWithCorrectDuration = fulfillmentFees.filter(
        (fulfillmentFee) => fulfillmentFee.duration === duration
    );

    fulfillmentFeesWithCorrectDuration.sort(
        (firstFee, secondFee) => firstFee.orderMinimumAmount - secondFee.orderMinimumAmount
    );

    const thresholdFee =
        fulfillmentFeesWithCorrectDuration.find((fulfillmentFee) => fulfillmentFee.orderMinimumAmount > cartTotal) ||
        fulfillmentFeesWithCorrectDuration.pop();

    return thresholdFee || defaultFulfillmentFee;
};

export const getFeeDisplayValue = (fee, customerHasAislesOnlineMembership) => {
    if (customerHasAislesOnlineMembership) {
        return fee.feeWithMembership === 0 ? 'Free' : `$${fee.feeWithMembership.toFixed(2)}`;
    }

    return fee.feeWithoutMembership === 0 ? 'Free' : `$${fee.feeWithoutMembership.toFixed(2)}`;
};

export const getServiceFeeDisplay = (serviceFee) => (serviceFee === 0 ? 'Free' : `$${serviceFee.toFixed(2)}`);

export const displayServiceFeeType = ({fulfillmentType}) => {
    if (Object.keys(fulfillmentDisplayTypes).includes(fulfillmentType)) {
        return `${fulfillmentDisplayTypes[fulfillmentType]} Fee`;
    }

    return 'Service Fee';
};
