import {FC, PropsWithChildren} from 'react';
import {useSelector} from 'react-redux';
import {useQuery} from '@apollo/client';
import styled, {css} from 'styled-components';
import {sizing, grey, spacing} from '@hy-vee/themes';
import Alert from '@hy-vee/web-core/lib/components/alert';

import {isLoading} from 'client/utils/view-helpers/loading-indicator-view-helpers';
import {SET_FULFILLMENT_CHANGE_PAGE_DATA_REQUEST} from 'client/action-types';
import {getProductNamesByProductIds} from 'client/graphql/queries/product-queries';
import {graphqlClient} from 'client/graphql/graphql-client';
import {useReservation} from 'client/hooks/use-reservation';
import {REVIEW} from 'client/enums/reservation-stepper-enums';
import AlertStatusType from 'client/enums/alert-status-types';
import {GHOST_VARIANT} from 'client/enums/alert-variant-types';
import {ICartItemState} from 'client/reducers/cart-items-reducer';
import {IFulfillmentChangeState} from 'client/reducers/fulfillment-change';
import {getProductNamesByProductIds as getProductNamesByProductIdsData} from 'autogen/getProductNamesByProductIds';
import {UNAVAILABLE, ALCOHOL} from 'server/enums/basket-continuity-removal-reasons';
import {useFeatureToggle} from 'client/context/feature-toggle';
import {SEPARATE_ALCOHOL_TRANSACTION} from 'client/enums/feature-toggle-names';

import DisableSectionLoader from '../disable-section-loader';

const StyledReviewContainer = styled.div<{isReview: boolean}>`
    border-bottom: 1px solid ${grey[300]};
    padding: ${spacing.medium};
    background-color: ${(props) => props.isReview && grey[100]};

    @media (min-width: 680px) {
        padding: ${spacing.large};
    }
`;

const StyledHeading = styled.h3`
    font-size: ${sizing[20]};
    font-weight: 400;
    line-height: 1.25;
    margin-bottom: ${spacing.small};
    font-weight: 400;
`;

const StyledSubtitleContainer = styled.div`
    display: flex;
    align-items: flex-start;

    svg:first-of-type {
        height: 24px;
        width: 24px;
    }

    @media (min-width: 856px) {
        align-items: center;
    }
`;

const StyledList = styled.ul`
    display: block;
    list-style-type: disc;
    margin: auto;
    padding: var(--spacing--md) 0 0 56px;
`;

const StyledListItem = styled.li`
    line-height: 1.25;
    margin-bottom: 8px;
`;

interface IStyledSubTitleProps {
    displayWithBlock: boolean;
}

const StyledSubTitle = styled.p<IStyledSubTitleProps>`
    font-weight: 200;

    ${({displayWithBlock}) =>
        displayWithBlock &&
        css`
            strong {
                display: block;
                padding-bottom: var(--spacing--x-sm);
            }
        `}
`;

const StyledDisclaimer = styled.p`
    margin-top: 4px;
`;

const StyledRedirectMessage = styled.div`
    padding-left: ${spacing.extraLarge};
    padding-top: ${spacing.small};
`;

interface IBasketContinuityReviewStepSubTitleProps {
    basketContinuityWasCalled: boolean;
    cartItems: ICartItemState[];
    doesPreventAlcoholChange: boolean;
    fulfillmentChange: IFulfillmentChangeState;
    isFulfillmentChangeLoading: boolean;
    separateAlcoholTransactionFeatureToggleEnabled: boolean;
}

interface IBasketContinuityReviewProps {
    redirectOnChange?: boolean;
}

const BasketContinuityReviewStepSubTitle: FC<
    PropsWithChildren<PropsWithChildren<IBasketContinuityReviewStepSubTitleProps>>
> = ({
    basketContinuityWasCalled,
    cartItems,
    doesPreventAlcoholChange,
    fulfillmentChange,
    isFulfillmentChangeLoading,
    separateAlcoholTransactionFeatureToggleEnabled
}) => {
    const removedItemsLength = fulfillmentChange?.removedCartItems?.length;

    let baseMessage;
    let showWarning = false;
    let subMessage = '';
    let availabilityMessage = '';

    if (isFulfillmentChangeLoading) {
        return null;
    }

    const isPreventAlcoholUnchanged =
        separateAlcoholTransactionFeatureToggleEnabled && !removedItemsLength && !doesPreventAlcoholChange;

    if ((!removedItemsLength && !basketContinuityWasCalled) || isPreventAlcoholUnchanged || !cartItems.length) {
        baseMessage = 'Everything looks good!';
    } else if (!removedItemsLength) {
        baseMessage = 'Good News! ';
        const itemsLength = cartItems.length;

        if (itemsLength > 1) {
            subMessage = `All ${itemsLength} items in your cart are sold at the location with your updated preferences.`;
            availabilityMessage = 'Items may not be in stock when shopped. Prices vary by location.';
        } else {
            subMessage = 'The item in your cart is sold at the location with your updated preferences.';
            availabilityMessage = 'Item may not be in stock when shopped. Prices vary by location.';
        }
    } else if (removedItemsLength && !separateAlcoholTransactionFeatureToggleEnabled) {
        showWarning = true;
        baseMessage = "We're sorry";

        if (removedItemsLength > 1) {
            subMessage = `, ${removedItemsLength} items in your cart are not available at this location.`;
        } else {
            subMessage = ', the item in your cart is not available at this location.';
        }
    } else {
        showWarning = true;
        baseMessage = 'The following items will be removed from your cart after saving';

        if (removedItemsLength > 1) {
            subMessage = `${removedItemsLength} items in your cart are not available at this location or are not available during your selected time slot.`;
        } else {
            subMessage =
                'The item in your cart is not available at this location or is not available during your selected time slot.';
        }
    }

    return (
        <StyledSubtitleContainer>
            <Alert
                status={showWarning ? AlertStatusType.WARNING_ALERT : AlertStatusType.SUCCESS_ALERT}
                variant={GHOST_VARIANT}
            >
                <StyledSubTitle
                    data-testid="basket-continuity-message"
                    displayWithBlock={showWarning && separateAlcoholTransactionFeatureToggleEnabled}
                >
                    <strong>{baseMessage}</strong>
                    {subMessage}
                    {availabilityMessage && <StyledDisclaimer>{availabilityMessage}</StyledDisclaimer>}
                </StyledSubTitle>
            </Alert>
        </StyledSubtitleContainer>
    );
};

const cartItemRemovalExplanationMap: Record<string, string> = {
    [ALCOHOL]: 'Alcohol',
    [UNAVAILABLE]: 'Not available'
};

const getCartItemRemovalExplanation = (reasons: string[]) => {
    if (reasons.length === 1) {
        return cartItemRemovalExplanationMap[reasons[0]];
    }

    return reasons
        .sort()
        .map((reason, index) =>
            index ? cartItemRemovalExplanationMap[reason]?.toLowerCase() : cartItemRemovalExplanationMap[reason]
        )
        .join(' & ');
};

const BasketContinuityReviewStep: FC<PropsWithChildren<PropsWithChildren<IBasketContinuityReviewProps>>> = ({
    redirectOnChange
}) => {
    const cartItems = useSelector((state: any) => state.cartItems);
    const fulfillmentChange = useSelector((state: any) => state.fulfillmentChange);
    const loadingIndicators = useSelector((state: any) => state.loadingIndicators);

    const {featureEnabled} = useFeatureToggle();
    const separateAlcoholTransactionFeatureToggleEnabled = featureEnabled(SEPARATE_ALCOHOL_TRANSACTION);
    const isFulfillmentChangeLoading = isLoading(loadingIndicators, SET_FULFILLMENT_CHANGE_PAGE_DATA_REQUEST);
    const productIds = fulfillmentChange.removedCartItems?.map(({cartItem}) => cartItem.productId);

    const {loading, data} = useQuery<getProductNamesByProductIdsData>(getProductNamesByProductIds, {
        client: graphqlClient(),
        fetchPolicy: 'no-cache',
        skip: !productIds.length || isFulfillmentChangeLoading,
        variables: {
            productIds
        }
    });
    const isAnythingLoading = isFulfillmentChangeLoading || loading;
    const {
        basketContinuityWasCalled,
        doesPreventAlcoholChange,
        selectedIndex,
        fulfillmentLocationId,
        fulfillmentTime,
        fulfillmentType
    } = useReservation();
    const isReview = selectedIndex[0] === REVIEW;
    const products = data?.products?.products ? data.products.products : [];

    const shouldAllowReviewAndSave = fulfillmentLocationId && fulfillmentType && fulfillmentTime;

    const productsBeingRemovedWithReason = products.map((product) => ({
        product,
        reason: fulfillmentChange.removedCartItems.find(
            ({cartItem}) => String(cartItem.productId) === product.productId
        )?.reason
    }));

    const getProductRemovalMessage = (productName: string, reason: string[]) =>
        separateAlcoholTransactionFeatureToggleEnabled
            ? `${getCartItemRemovalExplanation(reason)} - ${productName}`
            : productName;

    return (
        <StyledReviewContainer isReview={isReview}>
            <StyledHeading data-testid="reviewHeading">{'Review'}</StyledHeading>
            {shouldAllowReviewAndSave && (
                <DisableSectionLoader
                    addMinHeight={isAnythingLoading}
                    backgroundColor={'transparent'}
                    isLoading={isAnythingLoading}
                    minHeight={100}
                >
                    <BasketContinuityReviewStepSubTitle
                        basketContinuityWasCalled={basketContinuityWasCalled}
                        cartItems={cartItems}
                        data-testid={'bc-review-step-subtitle'}
                        doesPreventAlcoholChange={doesPreventAlcoholChange}
                        fulfillmentChange={fulfillmentChange}
                        isFulfillmentChangeLoading={isFulfillmentChangeLoading}
                        separateAlcoholTransactionFeatureToggleEnabled={separateAlcoholTransactionFeatureToggleEnabled}
                    />
                    <StyledList>
                        {productsBeingRemovedWithReason.map(({product, reason}) => (
                            <StyledListItem key={product.productId}>
                                {getProductRemovalMessage(product.name, reason)}
                            </StyledListItem>
                        ))}
                    </StyledList>
                    {redirectOnChange && Boolean(products.length) && (
                        <StyledRedirectMessage>{'You will be returned to the cart.'}</StyledRedirectMessage>
                    )}
                </DisableSectionLoader>
            )}
        </StyledReviewContainer>
    );
};

export default BasketContinuityReviewStep;
