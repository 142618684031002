import styled from 'styled-components';
import {OutlineButton} from '@hy-vee/web-core/lib/components/button';
import {colors, spacing} from '@hy-vee/themes';
import {CaretRightIcon} from '@hy-vee/icons';

import {useReservation} from '../../../hooks/use-reservation';
import {REVIEW, SKIPPED} from '../../../enums/reservation-stepper-enums';

const StyledOutlineButton = styled(OutlineButton)`
    border: 1px solid ${colors.grey[300]};
    background: none;
    height: 50px;
    margin-top: ${spacing.medium};
`;

const IconContainer = styled.span`
    padding-left: ${spacing.extraSmall};
    display: flex;
    align-self: center;

    svg path {
        fill: ${(props) => props.theme.primary};
    }
`;

const SkipTimeReservationButton = () => {
    const {onSetSelectedIndex, setFulfillmentTime} = useReservation();

    return (
        <StyledOutlineButton
            block
            onClick={() => {
                onSetSelectedIndex(REVIEW);
                setFulfillmentTime(SKIPPED);
            }}
            type="button"
        >
            {'Skip Time Selection'}
            <IconContainer>
                <CaretRightIcon size={'small'} />
            </IconContainer>
        </StyledOutlineButton>
    );
};

export default SkipTimeReservationButton;
