import {LocationEcommerceStatus} from '../../../../autogen/globalTypes';
import {getActiveCartForReservationStepper_carts_fulfillmentLocation_fulfillmentStoreLocation} from '../../../../autogen/getActiveCartForReservationStepper';

export const isEcommerceInactive = (
    fulfillmentStoreLocation:
        | getActiveCartForReservationStepper_carts_fulfillmentLocation_fulfillmentStoreLocation
        | null
        | undefined
) => fulfillmentStoreLocation?.ecommerceStatus === LocationEcommerceStatus.INACTIVE;

export const isCheckoutFlow = (pathName: string) => {
    const listOfCartViews = [
        '/checkout/cart',
        '/checkout/grocery-options',
        '/checkout/wic-options',
        '/checkout/substitutions'
    ];

    return listOfCartViews.includes(pathName);
};
