import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import styled from 'styled-components';
import {webFontStack} from '@hy-vee/themes/lib';
import {TabsContainer, PillTab} from '@hy-vee/web-tabs/lib/components/tabs-container';

import {selectTimeReservationTab} from '../../../action-creators';
import {dayOfWeek, monthAndDay} from '../../../utils/view-helpers/fulfillment-view-helpers';

const getAccessibilityLabel = (i, selectedTab, day) => {
    const date = `${dayOfWeek(day)} ${monthAndDay(day)}`;

    if (!day.isAvailable) {
        return `No times available for ${date}`;
    } else if (selectedTab === i) {
        return `${date}, Selected`;
    }

    return '';
};

const StyledPillTab = styled(PillTab)`
    &&,
    & :hover {
        opacity: 1;
    }
`;

const PillContent = styled.span`
    display: flex;
    flex-direction: column;
    font-family: ${webFontStack};

    span {
        line-height: 1.2;
    }

    span:last-child {
        font-weight: 300;
    }
`;

const DaySelectionTabs = ({actions, timeReservationDays, selectedTab}) => (
    <TabsContainer
        handleChange={(event, value) => {
            actions.selectTimeReservationTab(value);
        }}
        selectedIndex={selectedTab}
    >
        {timeReservationDays.map((day, i) => (
            <StyledPillTab
                aria-label={getAccessibilityLabel(i, selectedTab, day)}
                content={
                    <PillContent>
                        <span>{dayOfWeek(day)}</span>
                        <span>{monthAndDay(day)}</span>
                    </PillContent>
                }
                disabled={!day.isAvailable}
                key={day.date}
                title={getAccessibilityLabel(i, selectedTab, day)}
            />
        ))}
    </TabsContainer>
);

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            selectTimeReservationTab
        },
        dispatch
    )
});

const mapStateToProps = (state) => ({
    selectedTab: state.timeReservation.selectedTimeReservationTab,
    timeReservationDays: state.timeReservation.timeReservationDays
});

export default connect(mapStateToProps, mapDispatchToProps)(DaySelectionTabs);
