import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {hyvee, colors} from '@hy-vee/themes/lib';

import {selectTimeDurationTab} from '../../../action-creators';
import {getFeeDisplayValue, getThresholdFeeForTimeSlot} from '../../../utils/time-reservation-fee-helper';
import {getCartItemAccumulatedValues} from '../../../utils/view-helpers/calculation-view-helpers';

const getThresholdFeeMessage = ({
    duration,
    fulfillmentFees,
    customerHasAislesOnlineMembership,
    cartItems,
    products,
    storeProducts
}) => {
    const {subtotal: cartTotal} = getCartItemAccumulatedValues(cartItems, products, storeProducts);

    const fulfillmentFeeForTimeSlot = getThresholdFeeForTimeSlot(fulfillmentFees, duration, cartTotal);

    const fee = getFeeDisplayValue(fulfillmentFeeForTimeSlot, customerHasAislesOnlineMembership);

    return `${fee} for orders over $${Math.round(fulfillmentFeeForTimeSlot.orderMinimumAmount)}.`;
};

const getAccessibilityLabel = (i, selectedTab, duration) => {
    const window = `${duration}-hour window`;

    return selectedTab === i ? `${window}, Selected` : window;
};

const getDurationToDisplay = (duration) => {
    const readableDurations = {
        0.5: 'half',
        0.25: 'quarter'
    };

    return readableDurations[duration] || duration;
};

const TimeDurationList = styled.ul`
    display: flex;
`;

const TimeDurationFeesContainer = styled.div`
    display: flex;
`;

const TimeDurationListItem = styled.li`
    width: 100%;
`;

const DurationFee = styled.span`
    display: block;
    padding: 2px;
    background-color: ${colors.grey[100]};
    text-align: center;
    width: 100%;
    color: ${colors.grey[400]};

    @media (max-width: 800px) {
        :not(.selected) {
            display: none;
        }
    }
`;

const HourWindow = styled.span`
    display: block;
    padding: 6px;
`;

const Highlighter = styled.span`
    display: block;
    height: 2px;
    background: ${colors.grey[400]};

    &.selected {
        background: ${hyvee.primary};
    }
`;

const UnderlineTabButton = styled.button`
    width: 100%;

    &[aria-selected='true'] {
        color: ${hyvee.outline};
    }

    :hover {
        background-color: ${colors.grey[100]};
    }
`;

const TimeDurationTabs = ({
    actions,
    cartItems,
    customerHasAislesOnlineMembership,
    durations,
    fulfillmentFees,
    fulfillmentTimes,
    products,
    selectedTimeDurationTab,
    storeProducts
}) => (
    <div>
        <TimeDurationList>
            {durations.map((duration, i) => {
                const durationToDisplay = getDurationToDisplay(duration, fulfillmentTimes);

                return (
                    <TimeDurationListItem key={`${durationToDisplay}-hour-duration`}>
                        <UnderlineTabButton
                            aria-label={getAccessibilityLabel(i, selectedTimeDurationTab, durationToDisplay)}
                            aria-selected={i === selectedTimeDurationTab}
                            block="true"
                            onClick={() => {
                                actions.selectTimeDurationTab(i);
                            }}
                            role="tab"
                            title={getAccessibilityLabel(i, selectedTimeDurationTab, durationToDisplay)}
                            type="button"
                        >
                            <HourWindow>{`${durationToDisplay}-hour window`}</HourWindow>
                            <Highlighter className={i === selectedTimeDurationTab ? 'selected' : ''} />
                        </UnderlineTabButton>
                    </TimeDurationListItem>
                );
            })}
        </TimeDurationList>
        <TimeDurationFeesContainer>
            {durations.map((duration, i) => (
                <DurationFee className={i === selectedTimeDurationTab ? 'selected' : ''} key={`${duration}-hour-fee`}>
                    {getThresholdFeeMessage({
                        cartItems,
                        customerHasAislesOnlineMembership,
                        duration,
                        fulfillmentFees,
                        products,
                        storeProducts
                    })}
                </DurationFee>
            ))}
        </TimeDurationFeesContainer>
    </div>
);

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            selectTimeDurationTab
        },
        dispatch
    )
});

const mapStateToProps = (state) => ({
    cartItems: state.cartItems,
    customerHasAislesOnlineMembership: state.customerHasAislesOnlineMembership,
    fulfillmentFees: state.fulfillmentFees,
    products: state.products,
    selectedTimeDurationTab: state.timeReservation.selectedTimeDurationTab,
    storeProducts: state.storeProducts
});

export default connect(mapStateToProps, mapDispatchToProps)(TimeDurationTabs);
