import styled, {css} from 'styled-components';
import {colors, spacing, sizing} from '@hy-vee/themes/lib';
import {WarningIcon} from '@hy-vee/icons';

const getStartingRow = (row) => row * 2 + 1;
const timeReservationMobileBreakPoint = '650px';
const getBackgroundColor = ({backgroundColor, isMobile}) => {
    if (backgroundColor) {
        return backgroundColor;
    } else if (isMobile) {
        return 'white';
    }

    return 'inherit';
};

export const ModalBodyContent = styled.div`
    margin: ${(props) => props.addMargin && '20px'};
    padding-top: 0;
`;

export const ReservationsNotAvailableContainer = styled.div`
    text-align: center;
    padding: ${spacing.extraLarge};

    h2 {
        font-size: 20px;
        padding-bottom: 20px;
    }
`;

export const TextContainer = styled.div`
    display: flex;
    align-items: center;
    line-height: 1.2em;
    margin-right: ${spacing.medium};
    width: 45%;

    @media (max-width: 650px) {
        margin-right: 0;
        width: 100%;
        text-align: center;
    }
`;

export const StyledWarningIcon = styled(WarningIcon)`
    flex-basis: 20%;
    margin-right: ${spacing.small};
`;

export const TimeSelectContainer = styled.div`
    border-top: 1px solid ${colors.grey[200]};
    border-right: 1px solid ${colors.grey[200]};
    border-bottom: 1px solid ${colors.grey[200]};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    flex-direction: column;
    grid-column-start: 2;
    grid-row-start: span 2;
    grid-row: ${({row}) => `${getStartingRow(row)} / span 2`};
    justify-content: center;
    justify-self: end;
    margin-bottom: 4px;
    padding-bottom: 4px;
    padding-right: 16px;
    width: 100%;

    @media (min-width: 650px) {
        border-top: none;
        border-right: none;
        border-radius: 0;
        grid-column-start: 3;
        grid-row-start: span 1;
        padding-right: 16px;
        margin-bottom: 0;
        padding-bottom: 0;
        margin: 0;
    }
`;

export const StyledTimeGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, auto);

    @media (min-width: 650px) {
        grid-template-columns: auto 1fr auto;
    }
`;

export const EmptyPrice = styled.div`
    display: none;

    @media (min-width: ${timeReservationMobileBreakPoint}) {
        border-bottom: 1px solid ${colors.grey[200]};
        display: block;
        grid-column-start: 2;
        padding: 4px 0;
    }
`;

export const NotAvailableMessage = styled.div`
    border-top: 1px solid ${colors.grey[200]};
    border-right: 1px solid ${colors.grey[200]};
    border-bottom: 1px solid ${colors.grey[200]};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-weight: 300;
    grid-column-start: 2;
    grid-row-start: span 2;
    margin-bottom: 4px;
    padding: 4px 16px 4px 0;
    grid-row: ${({row}) => `${getStartingRow(row)} / span 2`};
    background-color: white;
    width: 100%;
    text-align: right;

    @media (min-width: ${timeReservationMobileBreakPoint}) {
        border-top: none;
        border-right: none;
        border-radius: 0;
        grid-column-start: 3;
        grid-row-start: span 1;
        margin-bottom: 0;
        margin: 0;
        padding-right: 16px;
    }
`;

export const TimeContainer = styled.div`
    border-top: 1px solid ${colors.grey[200]};
    border-left: 1px solid ${colors.grey[200]};
    border-top-left-radius: 4px;
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    padding: 4px 16px 0;
    line-height: 24px;
    grid-column-start: 1;

    ${({available, row}) =>
        available
            ? css`
                  grid-row: span 1;
              `
            : css`
                  border-bottom: 1px solid ${colors.grey[200]};
                  border-bottom-left-radius: 4px;
                  grid-row: ${getStartingRow(row)} / span 2;
                  padding-bottom: 4px;
                  margin-bottom: 4px;
              `};

    @media (min-width: ${timeReservationMobileBreakPoint}) {
        border-bottom: 1px solid ${colors.grey[200]};
        border-top: none;
        border-left: none;
        border-radius: 0;
        grid-row: span 1;
        margin-bottom: 0;
    }
`;

export const PriceColumn = styled.div`
    border-left: 1px solid ${colors.grey[200]};
    border-bottom: 1px solid ${colors.grey[200]};
    border-bottom-left-radius: 4px;
    display: flex;
    flex-direction: column;
    grid-column-start: 1;
    align-items: flex-start;
    justify-content: center;
    padding-left: 16px;
    margin-bottom: 4px;

    @media (min-width: 650px) {
        align-items: center;
        border-top: none;
        border-left: none;
        border-radius: 0;
        grid-column-start: 2;
        padding-left: 0;
        margin-bottom: 0;
    }
`;

export const PriceRow = styled.div`
    display: flex;
    flex-direction: row;
    grid-column-start: 1;
    align-items: baseline;
    justify-content: center;
    padding: 0;
    margin: 0;

    @media (min-width: 650px) {
        align-items: baseline;
        border-top: none;
        border-left: none;
        border-radius: 0;
        margin-bottom: 0;
        padding-bottom: 0;
    }
`;

export const Price = styled.div`
    font-weight: 700;
    font-size: 16px;
    padding: 0;
    margin: 0;

    @media (min-width: 650px) {
        align-items: center;
        border-top: none;
        border-left: none;
        border-radius: 0;
        margin-bottom: 0;
        padding-bottom: 0;
    }
`;

export const StrikeoutPrice = styled.span`
    font-weight: 300;
    font-size: 13px;
    color: ${colors.grey[400]};
    text-decoration: line-through;
    margin-left: 8px;

    @media (min-width: 650px) {
        align-items: center;
        border-top: none;
        border-left: none;
        border-radius: 0;
        margin-bottom: 0;
        padding-bottom: 0;
    }
`;

export const TimeSlotRow = styled.div`
    display: contents;
    grid-column: 1 / -1;

    ${(props) =>
        !props.onClick &&
        css`
            color: rgba(0, 0, 0, 0.847);
            pointer-events: none;
            > * {
                cursor: default;
            }
        `}

    &:hover {
        background-color: ${colors.grey[200]};

        > * {
            background-color: inherit;
        }
    }

    > * {
        background-color: ${({backgroundColor}) =>
            getBackgroundColor({
                backgroundColor,
                isMobile: true
            })};
        cursor: pointer;
    }

    @media (min-width: 650px) {
        > * {
            background-color: ${({backgroundColor}) =>
                getBackgroundColor({
                    backgroundColor,
                    isMobile: false
                })};
            padding-top: ${spacing.medium};
            padding-bottom: ${spacing.medium};
        }
        &:last-child {
            > * {
                border-bottom: none;
            }
        }
    }

    @supports not (display: contents) {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }
`;

export const Note = styled.span`
    font-weight: 300;
    font-size: ${sizing[14]};
`;

export const TimeRow = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
`;
