import {FC, PropsWithChildren, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {OutlineButton, Button} from '@hy-vee/web-core/lib/components/button';
import styled from 'styled-components';
import Input from '@hy-vee/web-core/lib/components/input';
import {spacing, hyvee} from '@hy-vee/themes';
import {LockIcon} from '@hy-vee/icons';
import {useQuery} from '@apollo/client';

import {DISPLAY_CLPE_SAVINGS} from 'client/enums/feature-toggle-names';

import {loadTimeReservationPageData, setReservationStepperIndex} from '../../../action-creators';
import {REVIEW, TIME_SLOT} from '../../../enums/reservation-stepper-enums';
import {useReservation} from '../../../hooks/use-reservation';
import {executeBasketContinuity} from '../../../services/basket-continuity-service';
import {getPickupLocationPasscode} from '../../../graphql/queries/pickup-location-queries';
import {graphqlClient} from '../../../graphql/graphql-client';
import getClientPasscodeValidationErrorMessage from '../fulfillment-change/form-validators/passcode-validator';
import {useFeatureToggle} from '../../../context/feature-toggle';

const StyledFooterForm = styled.form`
    display: flex;
    flex-direction: column;
    padding: ${spacing.medium};
    width: 100%;

    @media (min-width: 680px) {
        padding: ${spacing.large};
        flex-direction: row;
        align-items: flex-end;
    }
`;

const StyledLockerInputContainer = styled.div`
    display: flex;
    margin-bottom: ${spacing.medium};
    width: 100%;

    @media (min-width: 680px) {
        margin-bottom: 0;
        margin-right: ${spacing.medium};
    }
`;

const StyledButtonRow = styled.div`
    display: flex;
    justify-content: flex-end;
    height: 40px;
    width: 100%;
`;

const StyledOutlineButton = styled(OutlineButton)`
    width: 50%;

    @media (min-width: 680px) {
        width: 150px;
    }
`;

const StyledSubmitButton = styled(Button)`
    margin-left: ${spacing.medium};
    width: 50%;

    @media (min-width: 680px) {
        width: 150px;
    }
`;

const LockIconContainer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 0 ${spacing.extraSmall} ${spacing.extraSmall} 0;
    border-radius: 0;
    @media (min-width: 680px) {
        align-items: flex-start;
        padding-bottom: 0;
    }
`;

interface IReservationStepperFooterProps {
    closeModal: () => void;
    redirectOnChange?: boolean;
}

const passcodeIsCorrect = (localPasscode, pickupLocationDataWithPasscode) => {
    const passcode = pickupLocationDataWithPasscode?.pickupLocation?.passcode;

    return passcode && localPasscode === passcode;
};

const ReservationStepperFooter: FC<PropsWithChildren<PropsWithChildren<IReservationStepperFooterProps>>> = ({
    closeModal,
    redirectOnChange
}) => {
    const {
        setFulfillmentTime,
        pickupLocation,
        deliveryLocation,
        fulfillmentTime,
        fulfillmentType,
        onSetSelectedIndex,
        fulfillmentLocationId,
        selectedIndex,
        setIsUpdatingCart,
        storeId
    } = useReservation();

    const cart = useSelector((state: any) => state.cart);

    const isPasscodeRequired =
        pickupLocation?.hasPasscode &&
        (Number(fulfillmentLocationId) !== cart.fulfillmentLocationId ||
            Number(pickupLocation?.pickupLocationId) !== cart.pickupLocationId);

    const [localPasscode, setLocalPasscode] = useState('');
    const [passcodeValidationError, setPasscodeValidationError] = useState('');
    const dispatch = useDispatch();
    const isReview = selectedIndex[0] === REVIEW;
    const shouldAllowReviewAndSave = fulfillmentLocationId && fulfillmentType && fulfillmentTime;
    const {featureEnabled} = useFeatureToggle();

    const pickupLocationQueryResponse = useQuery(getPickupLocationPasscode, {
        client: graphqlClient(),
        skip: !pickupLocation?.pickupLocationId,
        variables: {
            pickupLocationId: Number(pickupLocation?.pickupLocationId)
        }
    });

    const isClpeFeatureToggleEnabled = featureEnabled(DISPLAY_CLPE_SAVINGS);

    return (
        <StyledFooterForm
            data-testid="reservation-stepper-form"
            onSubmit={async (e) => {
                e.preventDefault();

                if (!isPasscodeRequired || passcodeIsCorrect(localPasscode, pickupLocationQueryResponse.data)) {
                    try {
                        setIsUpdatingCart(true);
                        await executeBasketContinuity({
                            cart,
                            deliveryLocation,
                            fulfillmentTime,
                            fulfillmentType,
                            isClpeFeatureToggleEnabled,
                            newFulfillmentLocationId: fulfillmentLocationId,
                            pickupLocation,
                            redirectOnChange,
                            storeId
                        });

                        closeModal();
                    } catch (error) {
                        setFulfillmentTime(null);
                        dispatch(loadTimeReservationPageData(fulfillmentLocationId));
                        onSetSelectedIndex(TIME_SLOT);
                    }

                    setIsUpdatingCart(false);
                } else {
                    const validationErrorMessage = getClientPasscodeValidationErrorMessage(localPasscode, true);

                    setPasscodeValidationError(validationErrorMessage);
                }
            }}
        >
            {isPasscodeRequired && isReview && (
                <StyledLockerInputContainer>
                    <LockIconContainer>
                        <LockIcon color={hyvee.primary} size="large" />
                    </LockIconContainer>
                    <Input
                        autoFocus
                        error={passcodeValidationError}
                        id="locker-passcode"
                        label="Please enter your passcode"
                        margin={false}
                        onChange={(event) => {
                            setLocalPasscode(event.target?.value);
                            setPasscodeValidationError('');
                        }}
                        required={isPasscodeRequired}
                        type="password"
                    />
                </StyledLockerInputContainer>
            )}
            <StyledButtonRow>
                <StyledOutlineButton data-testid="stepper-cancel-button" onClick={() => closeModal()}>
                    {'Cancel'}
                </StyledOutlineButton>
                {shouldAllowReviewAndSave && (
                    <StyledSubmitButton
                        data-testid="stepper-save-button"
                        disabled={!localPasscode && isPasscodeRequired}
                        onClick={() => dispatch(setReservationStepperIndex(REVIEW))}
                        type="submit"
                    >
                        {'Save'}
                    </StyledSubmitButton>
                )}
            </StyledButtonRow>
        </StyledFooterForm>
    );
};

export default ReservationStepperFooter;
