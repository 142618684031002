import {connect} from 'react-redux';
import styled from 'styled-components';
import {parseISO, isBefore, isSameDay} from 'date-fns';

import TimeSlots from './time-slots';
import TimeDurationTabs from './time-duration-tabs';

const TimeSlotTable = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`;

const getFulfillmentTimesForSelectedTabs = (
    fulfillmentTimes,
    selectedTimeReservationTab,
    selectedTimeDurationTab,
    timeReservationDays
) =>
    fulfillmentTimes.filter(
        (fulfillmentTime) =>
            isBefore(new Date(), parseISO(fulfillmentTime.windowStart)) &&
            isSameDay(
                parseISO(timeReservationDays[selectedTimeReservationTab].date),
                parseISO(fulfillmentTime.windowStart)
            ) &&
            fulfillmentTime.duration ===
                timeReservationDays[selectedTimeReservationTab].durations[
                    Math.min(
                        selectedTimeDurationTab,
                        timeReservationDays[selectedTimeReservationTab].durations.length - 1
                    )
                ]
    );

const TimeReservationBody = ({
    fulfillmentTimes,
    selectedTimeReservationTab,
    selectedTimeDurationTab,
    timeReservationDays,
    fulfillmentTimeErrors
}) => {
    const durations = timeReservationDays.length ? timeReservationDays[selectedTimeReservationTab].durations : [];

    return (
        <TimeSlotTable data-testid="timeslot-table">
            {durations.length > 1 && <TimeDurationTabs durations={durations} fulfillmentTimes={fulfillmentTimes} />}
            <TimeSlots
                fulfillmentTimeErrors={fulfillmentTimeErrors}
                fulfillmentTimes={
                    timeReservationDays.length
                        ? getFulfillmentTimesForSelectedTabs(
                              fulfillmentTimes,
                              selectedTimeReservationTab,
                              selectedTimeDurationTab,
                              timeReservationDays
                          )
                        : []
                }
            />
        </TimeSlotTable>
    );
};

const mapStateToProps = (state) => ({
    fulfillmentTimeErrors: state.timeReservation.fulfillmentTimeErrors,
    fulfillmentTimes: state.fulfillmentTimes,
    selectedTimeDurationTab: state.timeReservation.selectedTimeDurationTab,
    selectedTimeReservationTab: state.timeReservation.selectedTimeReservationTab,
    timeReservationDays: state.timeReservation.timeReservationDays
});

export default connect(mapStateToProps)(TimeReservationBody);
