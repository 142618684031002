import {Base64} from 'js-base64';

interface IGetCitrusHomeBannerAdUrl {
    altText: string;
    bannerId: string;
    linkUrl: string;
    productIds: number[] | null;
}

export const getCitrusHomeBannerAdUrl = ({
    altText,
    bannerId,
    linkUrl,
    productIds
}: IGetCitrusHomeBannerAdUrl): string => {
    const encodedAltText = Base64.encode(altText);
    const encodedProductIds = productIds?.length ? Base64.encode(productIds.join(',')) : null;

    return encodedProductIds
        ? `/aisles-online/banner-campaign/home-banners?pi=${encodedProductIds}&at=${encodedAltText}&bannerId=${bannerId}`
        : linkUrl;
};
