import AlertStatusType from '../../enums/alert-status-types';
import {SKIPPED} from '../../enums/reservation-stepper-enums';

import {dayOfWeek, monthAndDay} from './fulfillment-view-helpers';
import {getFormattedTime} from './date-time-view-helpers';

export const getTimeSlotAccordionHeader = (
    fulfillmentTime,
    timeSlotSnatchedError,
    shouldShowTimeReservationView = true
) => {
    if (!shouldShowTimeReservationView) {
        return 'Select a day and time slot at checkout.';
    }

    if (!fulfillmentTime) return null;

    if (timeSlotSnatchedError) {
        return 'Your previous time is no longer available.';
    }

    if (fulfillmentTime === SKIPPED) {
        return (
            <>
                <strong>{'Skipped. '}</strong>
                {'Needs to be selected to place order.'}
            </>
        );
    }

    const date = new Date(fulfillmentTime.windowStart).toISOString();
    const startTime = getFormattedTime(fulfillmentTime.windowStart);
    const endTime = getFormattedTime(fulfillmentTime.windowEnd);

    return (
        <>
            <strong>{`${dayOfWeek({date})}, ${monthAndDay({date})},`}</strong>
            {` ${startTime} - ${endTime}`}
        </>
    );
};

export const getTimeSlotStatusType = (fulfillmentTime, timeSlotSnatchedError, shouldShowTimeReservationView = true) => {
    if (!shouldShowTimeReservationView) {
        return AlertStatusType.INFO_ALERT;
    }

    if (timeSlotSnatchedError || !fulfillmentTime || fulfillmentTime === SKIPPED) {
        return AlertStatusType.WARNING_ALERT;
    }

    return AlertStatusType.SUCCESS_ALERT;
};

export const getAddressAccordionHeader = (location) => {
    if (location) {
        const {addressOne, addressTwo, city, state, zip} = location;

        return (
            <>
                <strong>{`${addressOne}, ${addressTwo ? `${addressTwo} ` : ''}`}</strong>
                {`${city}, ${state} ${zip}`}
            </>
        );
    }

    return null;
};

export const getPickupReservationAccordionHeader = (location) => {
    if (location) {
        const {name, city, state, zip} = location;

        return (
            <>
                <strong>{`${name}, `}</strong>
                {`${city}, ${state} ${zip}`}
            </>
        );
    }

    return null;
};

export const getFulfillmentTimeIsValid = (fulfillmentTime, shouldShowTimeReservationView = true) =>
    (fulfillmentTime && fulfillmentTime.isAvailable) || fulfillmentTime === SKIPPED || !shouldShowTimeReservationView;
