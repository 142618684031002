import {v4} from 'uuid';
import {useMemo} from 'react';

import {getRandom} from 'client/utils/view-helpers/array-helpers';
import {getCitrusHomeBannerAdUrl} from 'client/utils/citrus-utils';
import {getCitrusBanners_citrusBanners} from 'autogen/getCitrusBanners';
import {useCitrusAds, useCitrusActions} from 'client/hooks/citrus-hooks';
import {IAdSection} from 'autogen/IAdSection';
import {IAd} from 'autogen/IAd';
import {IAdSlot} from 'autogen/IAdSlot';

import {defaultAds} from '../ads/default-ads';
import {loadingSecondaryImageTop} from '../loading-banners';
import {CmsAdType, IFireStoreAd, ICmsLargeAd} from '../types';
import {ILargeAdBannerProps} from '../ads/large-ad-banner';

import {IHomescreenData} from './use-home-screen-cms';

export interface IAdModel {
    bannerId: string;
    imageUri: string;
    mainHref: string;
    title: string | null;
    onLoad?: () => void;
    onClick?: () => void;
}

export interface IHomePageAd {
    mobile: IAdModel | null | undefined;
    web: IAdModel | null | undefined;
}

interface ICitrusSlot {
    mobileSlotId: string;
    webSlotId: string;
}

export interface IHomePageAds {
    largeAd: ICmsLargeAd | null;
    loading: boolean;
    ads: IHomePageAd[];
}
export interface IHomePageAdProp {
    adConfig: {
        citrusSlot: ICitrusSlot;
        cmsAdType: CmsAdType;
    }[];
    largeAdEnabled?: boolean;
    homeScreenData?: IHomescreenData | null;
}

const convertFirestoreAd = (ad: IFireStoreAd | null | undefined): IHomePageAd | null => {
    if (!ad) {
        return null;
    }

    return {
        mobile: {
            bannerId: ad.id.toString(),
            imageUri: ad.images.mobile,
            mainHref: ad.href,
            title: ad.title
        },
        web: {
            bannerId: ad.id.toString(),
            imageUri: ad.images.web,
            mainHref: ad.href,
            title: ad.title
        }
    };
};

const convertSanityAd = (ad: IAd | null | undefined): IHomePageAd | null => {
    if (!ad) {
        return null;
    }

    return {
        mobile: {
            bannerId: ad._id ?? '',
            imageUri: ad.imageMobile?.asset?.url ?? '',
            mainHref: ad.linkUrl ?? '',
            title: ad.imageMobile?.alt ?? ''
        },
        web: {
            bannerId: ad._id ?? '',
            imageUri: ad.imageWeb?.asset?.url ?? '',
            mainHref: ad.linkUrl ?? '',
            title: ad.imageWeb?.alt ?? ''
        }
    };
};

const convertSanityLargeAd = (largeAd: IAdSection['largeAd'] | null | undefined): ILargeAdBannerProps['largeAd'] => {
    if (!largeAd || !largeAd.linkUrl || !largeAd?.image?.asset?.url) {
        return null;
    }

    return {
        href: largeAd.linkUrl,
        id: largeAd._id ?? '',
        imageUrl: largeAd?.image?.asset?.url
    };
};

const convertLoadingAd = (ad): IHomePageAd => ({
    mobile: {
        bannerId: v4(),
        imageUri: ad.imageUri,
        mainHref: ad.mainHref,
        title: ad.title
    },
    web: {
        bannerId: v4(),
        imageUri: ad.imageUri,
        mainHref: ad.mainHref,
        title: ad.title
    }
});

const useHomePageAds = (props: IHomePageAdProp): IHomePageAds => {
    const citrusSlotIds = props?.adConfig.flatMap((ad) => [ad.citrusSlot.webSlotId, ad.citrusSlot.mobileSlotId]);

    const {ads: citrusData, loading: citrusLoading} = useCitrusAds(citrusSlotIds);

    const {reportClick, reportImpression} = useCitrusActions();

    const loading = citrusLoading;
    const convertCitrusAd = (ad: getCitrusBanners_citrusBanners): IAdModel | null => {
        const {altText, bannerId, imageUrl} = ad;

        return {
            bannerId,
            imageUri: imageUrl,
            mainHref: getCitrusHomeBannerAdUrl(ad),
            onClick: () => {
                reportClick(bannerId);
            },
            onLoad: () => {
                reportImpression(bannerId);
            },
            title: altText
        };
    };

    const getHyveeFirestoreAd = (
        fallbackType: CmsAdType
    ): {
        ad: IHomePageAd | null;
        prioritize: boolean;
    } => {
        const enabledAds = props.homeScreenData?.ads[fallbackType]?.filter((ad) => ad && ad.enabled);
        const priorityAds = enabledAds?.filter((ad) => ad && ad.prioritize);
        const ad = getRandom(priorityAds) || getRandom(enabledAds);
        const prioritize = Boolean(priorityAds && priorityAds?.length !== 0);

        return {
            ad: convertFirestoreAd(ad),
            prioritize
        };
    };

    const getCitrusAd = (slotId: string): IAdModel | null => {
        const citrusAd = citrusData?.citrusBanners?.find((x) => x.slotId === slotId);

        if (citrusAd) {
            return convertCitrusAd(citrusAd);
        }

        return null;
    };

    const getAd = (citrusSlot: ICitrusSlot, cmsAdType: CmsAdType): IHomePageAd | null => {
        if (loading) {
            return convertLoadingAd(loadingSecondaryImageTop);
        }

        const webCitrusAd = getCitrusAd(citrusSlot.webSlotId);
        const mobileCitrusAd = getCitrusAd(citrusSlot.mobileSlotId);
        const hyveeAd = getHyveeFirestoreAd(cmsAdType);

        if (hyveeAd.prioritize) {
            return hyveeAd.ad;
        }

        return {
            mobile: mobileCitrusAd || hyveeAd.ad?.mobile || defaultAds[cmsAdType].mobile,
            web: webCitrusAd || hyveeAd.ad?.web || defaultAds[cmsAdType].web
        };
    };

    const getLargeAd = (): ICmsLargeAd | null => {
        const enabledAds = props.homeScreenData?.largeAd?.filter((ad) => ad && ad.enabled);

        if (enabledAds?.length) {
            return getRandom(enabledAds);
        }

        return null;
    };

    return useMemo(() => {
        const ads = props.adConfig
            .map((x) => getAd(x.citrusSlot, x.cmsAdType))
            .filter((ad): ad is IHomePageAd => Boolean(ad?.mobile) && Boolean(ad?.web));

        return {
            ads,
            largeAd: getLargeAd(),
            loading
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, props]);
};

export const useHomePageSanityAd = (
    props: IAdSection
): {
    ads: IHomePageAd[];
    largeAd: ILargeAdBannerProps['largeAd'];
    loading: boolean;
} => {
    const citrusSlotIds: string[] = [];

    props.adSlots?.forEach((ad) => {
        if (ad?.citrusSlot?.mobileSlotId) {
            citrusSlotIds.push(ad?.citrusSlot?.mobileSlotId);
        }

        if (ad?.citrusSlot?.webSlotId) {
            citrusSlotIds.push(ad?.citrusSlot?.webSlotId);
        }
    });

    const {ads: citrusData, loading} = useCitrusAds(citrusSlotIds);

    const {reportClick, reportImpression} = useCitrusActions();

    const convertCitrusAd = (ad: getCitrusBanners_citrusBanners): IAdModel | null => {
        const {altText, bannerId, imageUrl} = ad;

        return {
            bannerId,
            imageUri: imageUrl,
            mainHref: getCitrusHomeBannerAdUrl(ad),
            onClick: () => {
                reportClick(bannerId);
            },
            onLoad: () => {
                reportImpression(bannerId);
            },
            title: altText
        };
    };

    const getSanityAd = (
        ads: (IAd | null)[]
    ): {
        ad: IHomePageAd | null;
        prioritize: boolean;
    } => {
        const priorityAds = ads?.filter((ad) => ad && ad.prioritize);
        const ad: IAd | null = getRandom(priorityAds) || getRandom(ads);
        const prioritize = Boolean(priorityAds && priorityAds?.length !== 0);

        return {
            ad: convertSanityAd(ad),
            prioritize
        };
    };

    const getCitrusAd = (slotId: string | null | undefined): IAdModel | null => {
        if (slotId) {
            const citrusAd = citrusData?.citrusBanners?.find((x) => x.slotId === slotId);

            if (citrusAd) {
                return convertCitrusAd(citrusAd);
            }
        }

        return null;
    };

    const getAd = ({ads, citrusSlot: citrusSlotId}: IAdSlot): IHomePageAd => {
        const hyveeAd = getSanityAd(ads ?? []);

        if (hyveeAd.prioritize && hyveeAd.ad) {
            return hyveeAd.ad;
        }

        const webCitrusAd = getCitrusAd(citrusSlotId?.webSlotId);
        const mobileCitrusAd = getCitrusAd(citrusSlotId?.mobileSlotId);

        return {
            mobile: mobileCitrusAd || hyveeAd.ad?.mobile,
            web: webCitrusAd || hyveeAd.ad?.web
        };
    };

    return useMemo(() => {
        return {
            ads: !loading ? props.adSlots?.map(getAd) ?? [] : [],
            largeAd: convertSanityLargeAd(props.largeAd),
            loading
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, props.adSlots, props.largeAd]);
};

export default useHomePageAds;
