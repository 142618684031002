import {useMemo} from 'react';
import {useMutation, useQuery} from '@apollo/client';

import {THREE_LEGGED_AUTH_TYPE, TWO_LEGGED_AUTH_TYPE} from 'client/enums/auth-types';
import {
    CreateEcommerceClickEventMutation,
    CreateEcommerceClickEventMutationVariables
} from 'autogen/CreateEcommerceClickEventMutation';
import {
    createEcommerceClickEventMutation,
    createEcommerceImpressionEventMutation
} from 'client/graphql/mutations/sponsored-product-tracking-mutations';
import {
    CreateEcommerceImpressionEventMutation,
    CreateEcommerceImpressionEventMutationVariables
} from 'autogen/CreateEcommerceImpressionEventMutation';
import IApolloQuery from 'client/types/apollo-query';
import {getCitrusBanners, getCitrusBannersVariables} from 'autogen/getCitrusBanners';
import {getCitrusBanners as getCitrusBannersQuery} from 'client/graphql/queries/banner-queries';

import {useCustomerData} from './customer-hooks';

export interface IUseCitrusAds {
    ads?: getCitrusBanners;
    loading: boolean;
}

export interface IUseCitrusActions {
    reportClick: (adId: string) => void;
    reportImpression: (adId: string) => void;
}

export const useCitrusAds = (slotIds: string[] = []): IUseCitrusAds => {
    const skipAdQuery = !(slotIds && slotIds.length > 0);

    const {data: ads, loading} = useQuery<getCitrusBanners, IApolloQuery<getCitrusBannersVariables>>(
        getCitrusBannersQuery,
        {
            skip: skipAdQuery,
            variables: {
                authType: TWO_LEGGED_AUTH_TYPE,
                bannerSlotIds: slotIds
            }
        }
    );

    return {
        ads,
        loading
    };
};

export const useCitrusActions = (): IUseCitrusActions => {
    const {activeCartId} = useCustomerData();

    const [createImpressionEvent] = useMutation<
        CreateEcommerceImpressionEventMutation,
        IApolloQuery<CreateEcommerceImpressionEventMutationVariables>
    >(createEcommerceImpressionEventMutation);

    const [createClickEvent] = useMutation<
        CreateEcommerceClickEventMutation,
        IApolloQuery<CreateEcommerceClickEventMutationVariables>
    >(createEcommerceClickEventMutation);

    return useMemo(() => {
        const reportClick = (adId: string) => {
            createClickEvent({
                context: {
                    fetchOptions: {
                        keepalive: true
                    }
                },
                variables: {
                    authType: activeCartId ? THREE_LEGGED_AUTH_TYPE : TWO_LEGGED_AUTH_TYPE,
                    cartId: activeCartId ? activeCartId : null,
                    vendorAdId: adId
                }
            });
        };

        const reportImpression = (adId: string) => {
            createImpressionEvent({
                variables: {
                    authType: activeCartId ? THREE_LEGGED_AUTH_TYPE : TWO_LEGGED_AUTH_TYPE,
                    cartId: activeCartId ? activeCartId : null,
                    vendorAdId: adId
                }
            });
        };

        return {
            reportClick,
            reportImpression
        };
    }, [createClickEvent, createImpressionEvent, activeCartId]);
};
