import {compareAsc, parseISO} from 'date-fns';

import {EXPRESS} from '../../../enums/fulfillment-priority-types';

import FulfillmentTime from './fulfillment-time';
import FulfillmentTimeError from './fulfillment-time-error';
import {StyledTimeGrid} from './styles';

const timeWindowsDontMatch = (firstTimeSlot, secondTimeSlot) => {
    return (
        firstTimeSlot.windowStart !== secondTimeSlot.windowStart && firstTimeSlot.windowEnd !== secondTimeSlot.windowEnd
    );
};

const scheduleIdsMatch = (firstTimeSlot, secondTimeSlot) => {
    return firstTimeSlot.fulfillmentScheduleId === secondTimeSlot.fulfillmentScheduleId;
};

const removeOverlappingTimeSlots = (timeSlots, timeSlot) => {
    if (timeSlot.priorityType === EXPRESS && timeSlot.isAvailable) {
        return timeSlots.filter((time) => timeWindowsDontMatch(time, timeSlot) || scheduleIdsMatch(time, timeSlot));
    }

    return timeSlots;
};

const renderFulfillmentTimeView = ({fulfillmentTime, fulfillmentTimeErrors, index}) => {
    if (fulfillmentTimeErrors[fulfillmentTime.fulfillmentTimeId]) {
        return (
            <FulfillmentTimeError
                errorType={fulfillmentTimeErrors[fulfillmentTime.fulfillmentTimeId]}
                fulfillmentTime={fulfillmentTime}
                key={fulfillmentTime.fulfillmentTimeId}
            />
        );
    }

    return (
        <FulfillmentTime
            fulfillmentTime={fulfillmentTime}
            index={fulfillmentTime.fulfillmentTimeId}
            key={fulfillmentTime.fulfillmentTimeId}
            row={index}
        />
    );
};

const TimeSlots = ({fulfillmentTimes, fulfillmentTimeErrors}) => {
    const filteredAndSortedTimeSlots = fulfillmentTimes
        .reduce(removeOverlappingTimeSlots, fulfillmentTimes)
        .filter((timeSlot) => timeSlot.priorityType !== EXPRESS || timeSlot.isAvailable)
        .sort((firstTimeSlot, secondTimeSlot) =>
            compareAsc(parseISO(firstTimeSlot.windowStart), parseISO(secondTimeSlot.windowStart))
        );

    return (
        <StyledTimeGrid fulfillmentTimes={filteredAndSortedTimeSlots}>
            {filteredAndSortedTimeSlots.map((fulfillmentTime, index) =>
                renderFulfillmentTimeView({
                    fulfillmentTime,
                    fulfillmentTimeErrors,
                    index
                })
            )}
        </StyledTimeGrid>
    );
};

export default TimeSlots;
