import {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Alert from '@hy-vee/web-core/lib/components/alert';

import {loadTimeReservationPageData} from '../action-creators';
import {hasApiCallErrorMessage} from '../utils/view-helpers/error-view-helpers';
import {isLoading} from '../utils/view-helpers/loading-indicator-view-helpers';
import {SET_TIME_RESERVATION_PAGE_DATA_REQUEST} from '../action-types';
import {SKIPPED} from '../enums/reservation-stepper-enums';
import AlertStatusType from '../enums/alert-status-types';
import {GHOST_VARIANT} from '../enums/alert-variant-types';

import DaySelectionTabs from './components/time-reservation/day-selection-tabs';
import TimeReservationBody from './components/time-reservation/time-reservation-body';
import ReloadDataButton from './components/reload-data-button';
import SkipTimeReservationButton from './components/time-reservation/skip-time-reservation-button';
import {ModalBodyContent, ReservationsNotAvailableContainer} from './components/time-reservation/styles';

const hasAvailableTimeSlots = (fulfillmentExpiration, timeReservationDays) =>
    timeReservationDays.length && timeReservationDays.some((day) => day.isAvailable);

const shouldRenderBody = (apiCallErrorMessage, loadingIndicators) =>
    !isLoading(loadingIndicators, SET_TIME_RESERVATION_PAGE_DATA_REQUEST) &&
    !hasApiCallErrorMessage(apiCallErrorMessage);

const hasApiCallErrorMessageView = (apiCallErrorMessage) =>
    hasApiCallErrorMessage(apiCallErrorMessage) && (
        <ReservationsNotAvailableContainer>
            <h2>{'Reservations are not available at this moment.'}</h2>
            <p>
                {
                    "Don't worry, your cart will be safe and sound during this time. Please try to reserve your time again soon."
                }
            </p>
            <p>{'To report an issue with this reservation, please call (800) 772-4098.'}</p>
            <ReloadDataButton action={'loadTimeReservationPageData'} />
        </ReservationsNotAvailableContainer>
    );

class TimeReservation extends Component {
    componentDidMount() {
        this.props.actions.loadTimeReservationPageData(this.props.fulfillmentLocationId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.fulfillmentLocationId !== prevProps.fulfillmentLocationId) {
            this.props.actions.loadTimeReservationPageData(this.props.fulfillmentLocationId);
        }
    }

    render() {
        const showSkippedButton = !this.props.isSelected || this.props.isSelected === SKIPPED;

        return (
            <>
                {shouldRenderBody(this.props.apiCallErrorMessage, this.props.loadingIndicators) &&
                    (hasAvailableTimeSlots(this.props.cart.fulfillmentExpiration, this.props.timeReservationDays) ? (
                        <ModalBodyContent>
                            <DaySelectionTabs />
                            {showSkippedButton && <SkipTimeReservationButton />}
                            <TimeReservationBody />
                        </ModalBodyContent>
                    ) : (
                        <>
                            <Alert status={AlertStatusType.WARNING_ALERT} variant={GHOST_VARIANT}>
                                {'Time slots are not available. '}
                            </Alert>
                            <SkipTimeReservationButton />
                        </>
                    ))}
                {hasApiCallErrorMessageView(this.props.apiCallErrorMessage)}
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            loadTimeReservationPageData
        },
        dispatch
    )
});

const mapStateToProps = (state) => ({
    apiCallErrorMessage: state.apiCallErrorMessage,
    cart: state.cart,
    loadingIndicators: state.loadingIndicators,
    timeReservationDays: state.timeReservation.timeReservationDays
});

export default connect(mapStateToProps, mapDispatchToProps)(TimeReservation);
