import {FC, PropsWithChildren, ReactNode} from 'react';
import styled, {css} from 'styled-components';
import Collapse from '@hy-vee/web-core/lib/components/collapse';
import {spacing, grey} from '@hy-vee/themes';

const StyledAccordion = styled.div<{isOpen: boolean}>`
    background-color: ${(props) => (props.isOpen ? grey[100] : undefined)};
    border-bottom: 1px solid ${grey[300]};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
`;
const StyledButton = styled.button<{disabled: boolean}>`
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: none;

    :hover,
    :focus,
    svg:focus {
        outline: none;
    }

    ${(props) =>
        props.disabled &&
        css`
            background-color: 'auto';
            cursor: 'not-allowed';
        `};
`;

const StyledPanelContainer = styled.div`
    padding: ${spacing.medium};
    padding-top: ${spacing.small};

    @media (min-width: 680px) {
        padding: ${spacing.large};
        padding-top: ${spacing.small};
    }
`;

const StyledCollapse = styled(Collapse)`
    margin: 10px 0 0;
    text-align: left;
`;

interface IReservationAccordionItemProps {
    accordionText?: string;
    children: ReactNode[];
    disabled: boolean;
    onClick?: () => void;
    isOpen: boolean;
    testId?: string;
}

const ReservationStepperAccordionItem: FC<PropsWithChildren<PropsWithChildren<IReservationAccordionItemProps>>> = ({
    accordionText,
    children,
    disabled,
    isOpen,
    onClick,
    testId,
    ...props
}) => {
    return (
        <StyledAccordion isOpen={isOpen}>
            <StyledButton
                aria-expanded={isOpen}
                aria-label={`change ${accordionText}`}
                data-testid={testId}
                disabled={disabled}
                onClick={onClick}
                type={'button'}
                {...props}
            >
                {children[0]}
            </StyledButton>
            <StyledCollapse isOpen={isOpen}>
                <StyledPanelContainer>{children[1]}</StyledPanelContainer>
            </StyledCollapse>
        </StyledAccordion>
    );
};

export default ReservationStepperAccordionItem;
