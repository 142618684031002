import {getCartItemAccumulatedValues} from '../../../utils/view-helpers/calculation-view-helpers';
import {getFeeDisplayValue, getFeeForTimeSlot} from '../../../utils/time-reservation-fee-helper';
import {EXPRESS} from '../../../enums/fulfillment-priority-types';

import {Price, StrikeoutPrice, Note, PriceRow, PriceColumn} from './styles';

const getFeeForFulfillmentTime = ({
    fulfillmentTime,
    fulfillmentFees,
    customerHasAislesOnlineMembership,
    cartItems,
    products,
    storeProducts
}) => {
    const {subtotal: cartTotal} = getCartItemAccumulatedValues(cartItems, products, storeProducts);

    const fulfillmentFeeForTimeSlot = getFeeForTimeSlot(fulfillmentFees, fulfillmentTime, cartTotal);

    return getFeeDisplayValue(fulfillmentFeeForTimeSlot, customerHasAislesOnlineMembership);
};

const getSecondaryText = ({priorityType}) => {
    if (priorityType === EXPRESS) {
        return <Note>{'Get it faster'}</Note>;
    }

    return undefined;
};

const getOriginalPrice = ({fulfillmentTime, fulfillmentFees, cartItems, products, storeProducts}) => {
    const {subtotal: cartTotal} = getCartItemAccumulatedValues(cartItems, products, storeProducts);

    const fulfillmentFeeForTimeSlot = getFeeForTimeSlot(fulfillmentFees, fulfillmentTime, cartTotal);

    return `$${fulfillmentFeeForTimeSlot.feeWithoutMembership.toFixed(2)}`;
};

export const membershipAndNonmembershipFeesAreEqual = ({
    fulfillmentTime,
    fulfillmentFees,
    cartItems,
    products,
    storeProducts
}) => {
    const {subtotal: cartTotal} = getCartItemAccumulatedValues(cartItems, products, storeProducts);

    const fulfillmentFeeForTimeSlot = getFeeForTimeSlot(fulfillmentFees, fulfillmentTime, cartTotal);

    return fulfillmentFeeForTimeSlot.feeWithMembership === fulfillmentFeeForTimeSlot.feeWithoutMembership;
};

const FulfillmentPrice = ({
    index,
    cartItems,
    customerHasAislesOnlineMembership,
    fulfillmentFees,
    fulfillmentTime,
    products,
    row,
    storeProducts,
    backgroundColor
}) => {
    const fulfillmentTimeFee = getFeeForFulfillmentTime({
        cartItems,
        customerHasAislesOnlineMembership,
        fulfillmentFees,
        fulfillmentTime,
        products,
        storeProducts
    });

    return (
        <PriceColumn backgroundColor={backgroundColor} flexStyle id={`fulfillment-price-${index}`} row={row}>
            <PriceRow>
                <Price>
                    <span>{fulfillmentTimeFee}</span>
                </Price>
                {customerHasAislesOnlineMembership &&
                    !membershipAndNonmembershipFeesAreEqual({
                        cartItems,
                        fulfillmentFees,
                        fulfillmentTime,
                        products,
                        storeProducts
                    }) && (
                        <StrikeoutPrice data-testid={'fulfillment-time-strikeout-price'}>
                            {getOriginalPrice({
                                cartItems,
                                fulfillmentFees,
                                fulfillmentTime,
                                products,
                                storeProducts
                            })}
                        </StrikeoutPrice>
                    )}
            </PriceRow>
            {getSecondaryText(fulfillmentTime)}
        </PriceColumn>
    );
};

export default FulfillmentPrice;
