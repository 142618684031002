export const DISPLAY_CLPE_SAVINGS = 'cj-display-clpe-savings';
export const MN_ALCOHOL_PICKUP = 'aoh-mn-alcohol-pickup';
export const USE_ITEM_API_PRICING = 'mobile-app-pricing-from-item-api';
export const MTO_IS_SHOPPERKIT_ENABLED = 'mto-is-shopperkit-enabled';

// note: this is on 100% in production
// see: https://api.prod.hy-vee.cloud/unleash/#/features/strategies/cj-retail-item-pricing
export const MOBILE_RETAIL_ITEM_PRICING = 'cj-retail-item-pricing';

export const PRICING_TRANSPARENCY = 'pricing-transparency';
export const EWIC_FORCE_SAD_PATH = 'aisles-online-ewic-force-sad-path';
export const EWIC_STORES = 'aisles-online-ewic-stores';
export const AISLES_ONLINE_RISKIFIED = 'aisles-online-riskified';
export const AISLES_ONLINE_RISKIFIED_BLOCK_ORDERS = 'aisles-online-riskified-block-orders';
export const MTO_IS_RISKIFIED_ENABLED = 'mto-riskified';
