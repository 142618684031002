import {useQuery, ApolloError} from '@apollo/client';

import {getBasicCustomerInformation} from 'client/graphql/queries/cart-queries';
import {useCustomer} from 'client/hooks/customer-hooks';
import {GetBasicCustomerInformation, GetBasicCustomerInformationVariables} from 'autogen/GetBasicCustomerInformation';
import IApolloQuery from 'client/types/apollo-query';

export const useHomePageCustomerInfo = (): {
    error: ApolloError | undefined;
    loading: boolean;
} => {
    const customer = useCustomer();

    const {loading, error} = useQuery<GetBasicCustomerInformation, IApolloQuery<GetBasicCustomerInformationVariables>>(
        getBasicCustomerInformation,
        {
            skip: !customer?.customerId,
            variables: {
                batchKey: 'init',
                customerId: Number(customer.customerId),
                customerUuid: customer.customerUuid?.toLowerCase(),
                includeProductCardData: false
            }
        }
    );

    return {
        error,
        loading
    };
};
