import {FC, PropsWithChildren, ReactNode} from 'react';
import styled from 'styled-components';
import Alert from '@hy-vee/web-core/lib/components/alert';
import {colors, sizing, spacing} from '@hy-vee/themes';
import {LinkButton} from '@hy-vee/web-core';

import AlertStatusType from '../../../enums/alert-status-types';
import {GHOST_VARIANT} from '../../../enums/alert-variant-types';

import InStoreAvailabilityText from './in-store-availability-text';
import styles from './reservation-stepper.module.css';

const StyledHeaderContainer = styled.div`
    padding: ${spacing.medium};
    text-align: left;

    @media (min-width: 680px) {
        padding: ${spacing.large};
    }
`;

const StyledHeading = styled.h3`
    color: ${colors.grey[500]};
    font-size: ${sizing[20]};
    max-width: 111px;

    @media (min-width: 415px) {
        max-width: none;
    }
`;

const StyledHeaderRow = styled.div`
    display: flex;
    align-items: baseline;
    margin-bottom: ${spacing.small};
`;

const Subtext = styled.span`
    color: ${colors.grey[400]};
    font-size: ${sizing[16]};
    line-height: ${sizing[22]};
    font-weight: 200;
    padding-left: ${spacing.small};

    ${({hidden}) =>
        hidden &&
        `
        hidden: true
  `}
`;

const StyledDisclaimerText = styled.p`
    color: #999999;
    font-size: ${sizing[14]};
    font-weight: 300;
    line-height: 19px;
    margin-left: ${spacing.extraLarge};
`;

const StyledLinkButton = styled(LinkButton)`
    margin-left: 12px;
    font-size: ${sizing[14]};
`;

const StyledNotSelectedText = styled.p`
    font-size: ${sizing[14]};
    color: #999999;
    line-height: 1;
`;

const StyledAlertContainer = styled.div`
    display: flex;
    align-items: center;

    svg:first-of-type {
        height: 24px;
        width: 24px;
    }
`;

interface IReservationAccordionHeaderProps {
    alcoholReservationDisclaimer?: string;
    isDeliveryOnly: boolean;
    isSelected: boolean;
    isShoppingPreferenceHeader?: boolean;
    isTimeSlotHeader?: boolean;
    shouldShowThirdPartyDisclaimer?: boolean;
    title: string;
    subtitle: ReactNode | string;
    notSelectedText?: string;
    onChange: () => void;
    showChangeButton: boolean;
    statusType?: string;
    testId?: string;
    showInStoreOnlyText?: boolean;
    isLocationHeader?: boolean;
    shouldShowTimeReservationView?: boolean;
}

export const ReservationAccordionHeader: FC<PropsWithChildren<PropsWithChildren<IReservationAccordionHeaderProps>>> = ({
    isDeliveryOnly,
    isSelected,
    isTimeSlotHeader = false,
    isLocationHeader = false,
    notSelectedText = 'Not Selected',
    onChange,
    shouldShowThirdPartyDisclaimer = false,
    alcoholReservationDisclaimer = '',
    showChangeButton,
    showInStoreOnlyText = false,
    subtitle,
    statusType = AlertStatusType.SUCCESS_ALERT,
    title,
    shouldShowTimeReservationView = true
}) => {
    return (
        <StyledHeaderContainer data-testid={title}>
            <StyledHeaderRow>
                <StyledHeading>{title}</StyledHeading>
                {shouldShowTimeReservationView &&
                    (showChangeButton ? (
                        <StyledLinkButton onClick={onChange}>{'Change'}</StyledLinkButton>
                    ) : (
                        <Subtext hidden={!isTimeSlotHeader}>{'Held for 2 hours'}</Subtext>
                    ))}
            </StyledHeaderRow>
            {isSelected ? (
                <StyledAlertContainer>
                    <Alert status={statusType} variant={GHOST_VARIANT}>
                        {subtitle}
                    </Alert>
                </StyledAlertContainer>
            ) : (
                <StyledNotSelectedText data-testid={'not-selected-text'}>{notSelectedText}</StyledNotSelectedText>
            )}
            {alcoholReservationDisclaimer && (
                <StyledDisclaimerText>{alcoholReservationDisclaimer}</StyledDisclaimerText>
            )}
            {shouldShowThirdPartyDisclaimer && (
                <StyledDisclaimerText>
                    {'A third-party service may deliver your order on behalf of Hy-Vee'}
                </StyledDisclaimerText>
            )}
            {isLocationHeader && isSelected ? (
                <div className={styles.availableTextHeaderContainer}>
                    <InStoreAvailabilityText isDeliveryOnly={isDeliveryOnly} isStoreUnavailable={showInStoreOnlyText} />
                </div>
            ) : null}
        </StyledHeaderContainer>
    );
};
