import {FC, PropsWithChildren} from 'react';
import {grey, sizing, spacing, breakpoints, green} from '@hy-vee/themes';
import styled from 'styled-components';
import {OrderTypes, PICKUP_FULFILLMENT_TYPES} from '@hy-vee/shared-utils-aisles-online';

import SelectConfirmButton from '../shared/select-confirm-button';
import {selectedBackgroundColor, StyledSelectionCheckmark} from '../../../styles/style-constants';

const StyledFulfillmentTypeCard = styled.div<{isConfirm?: boolean}>`
    background-color: ${(props) => props.isConfirm && selectedBackgroundColor};
    display: grid;
    flex-wrap: wrap;
    padding: ${spacing.medium};
    width: 100%;
    cursor: pointer;

    &:hover {
        background: ${grey[200]};
    }

    &:first-child {
        border-bottom: 1px solid ${grey[300]};
    }

    @media (min-width: ${breakpoints.small}) {
        display: flex;
        flex-direction: row;
        padding: ${sizing[12]};
    }
`;

const StyledCardHeader = styled.p`
    margin-top: 3px;
    font-weight: bold;
    width: 50%;

    @media (min-width: ${breakpoints.small}) {
        width: 100%;
    }
`;

const StyledSubtext = styled.p`
    color: ${grey[400]};
    font-size: ${sizing[14]};
`;

const StyledMobileButtonContainer = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    margin-top: 2px;

    @media (min-width: ${breakpoints.small}) {
        display: none;
    }
`;

const StyledDesktopButtonContainer = styled.div`
    display: none;
    flex-grow: 1;
    justify-content: flex-end;

    @media (min-width: ${breakpoints.small}) {
        display: flex;
    }
`;

const StyledOptionAndButtonContainer = styled.div`
    display: flex;
`;

const isPickup = (fulfillmentType: OrderTypes): boolean => PICKUP_FULFILLMENT_TYPES.includes(fulfillmentType);
const isDelivery = (fulfillmentType: OrderTypes): boolean => fulfillmentType === OrderTypes.DELIVERY;
const getPickupSubtext = (requireSeparateAlcoholOrder) =>
    `Starting at $0.00${requireSeparateAlcoholOrder ? '. There are two pickup locations.' : ''}`;

interface IShoppingPreferencesBodyProps {
    shouldShowAlcoholDeliverySubtext: boolean;
    customerHasAislesOnlineMembership: boolean;
    requireSeparateAlcoholOrder: boolean;
    fulfillmentType: OrderTypes;
    onSelectPickup: () => void;
    onSelectDelivery: () => void;
}

const ShoppingPreferencesBody: FC<PropsWithChildren<PropsWithChildren<IShoppingPreferencesBodyProps>>> = ({
    shouldShowAlcoholDeliverySubtext,
    customerHasAislesOnlineMembership,
    requireSeparateAlcoholOrder,
    fulfillmentType,
    onSelectPickup,
    onSelectDelivery
}) => {
    return (
        <>
            <StyledFulfillmentTypeCard isConfirm={isPickup(fulfillmentType)} onClick={onSelectPickup}>
                <div>
                    <StyledOptionAndButtonContainer>
                        <StyledCardHeader>
                            {'Pickup'}
                            {isPickup(fulfillmentType) && (
                                <StyledSelectionCheckmark color={green.mediumGreen} size="small" />
                            )}
                        </StyledCardHeader>
                        <StyledMobileButtonContainer>
                            <SelectConfirmButton
                                accessibilityLabel="Select shopping preference of pickup"
                                isConfirm={isPickup(fulfillmentType)}
                                title="Select Shopping Preference of Pickup"
                            />
                        </StyledMobileButtonContainer>
                    </StyledOptionAndButtonContainer>
                    <StyledSubtext>{getPickupSubtext(requireSeparateAlcoholOrder)}</StyledSubtext>
                </div>
                <StyledDesktopButtonContainer>
                    <SelectConfirmButton
                        accessibilityLabel="Select shopping preference of pickup"
                        isConfirm={isPickup(fulfillmentType)}
                        title="Select Shopping Preference of Pickup"
                    />
                </StyledDesktopButtonContainer>
            </StyledFulfillmentTypeCard>
            <StyledFulfillmentTypeCard isConfirm={isDelivery(fulfillmentType)} onClick={onSelectDelivery}>
                <div>
                    <StyledOptionAndButtonContainer>
                        <StyledCardHeader>
                            {'Delivery'}
                            {isDelivery(fulfillmentType) && (
                                <StyledSelectionCheckmark color={green.mediumGreen} size="small" />
                            )}
                        </StyledCardHeader>
                        <StyledMobileButtonContainer>
                            <SelectConfirmButton
                                accessibilityLabel="Select shopping preference of delivery"
                                isConfirm={isDelivery(fulfillmentType)}
                                title="Select Shopping Preference of Delivery"
                            />
                        </StyledMobileButtonContainer>
                    </StyledOptionAndButtonContainer>
                    <div>
                        {customerHasAislesOnlineMembership ? (
                            <StyledSubtext>{'Starting at $0.00 with membership pricing'}</StyledSubtext>
                        ) : (
                            <StyledSubtext>{'Starting at $9.95'}</StyledSubtext>
                        )}
                        {shouldShowAlcoholDeliverySubtext && (
                            <StyledSubtext>
                                {'Alcohol cannot be delivered and will be removed from your cart when you save'}
                            </StyledSubtext>
                        )}
                        <StyledSubtext>
                            {'A third-party service may deliver your order on behalf of Hy-Vee'}
                        </StyledSubtext>
                    </div>
                </div>
                <StyledDesktopButtonContainer>
                    <SelectConfirmButton
                        accessibilityLabel="Select shopping preference of delivery"
                        isConfirm={isDelivery(fulfillmentType)}
                        title="Select Shopping Preference of Delivery"
                    />
                </StyledDesktopButtonContainer>
            </StyledFulfillmentTypeCard>
        </>
    );
};

export default ShoppingPreferencesBody;
