import {connect} from 'react-redux';
import styled from 'styled-components';

import {isReservationStepperTimeSelected} from '../../../utils/view-helpers/fulfillment-view-helpers';
import {useReservation} from '../../../hooks/use-reservation';
import SelectConfirmButton from '../shared/select-confirm-button';

import {TimeSelectContainer} from './styles';

const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const getAccessibilityLabel = (index) =>
    `day-of-week month-and-day fulfillment-time-${index} fulfillment-cutoff-${index} fulfillment-price-${index}`;

const FulfillmentTimeSelect = ({index, fulfillmentTime, row, backgroundColor}) => {
    const reservation = useReservation();
    const reservationStepperFulfillmentTime = reservation?.fulfillmentTime;
    const isSelected = isReservationStepperTimeSelected(fulfillmentTime, reservationStepperFulfillmentTime);

    return (
        <TimeSelectContainer backgroundColor={backgroundColor} role="presentation" row={row}>
            <StyledButtonContainer>
                <SelectConfirmButton
                    accessibilityLabelledBy={getAccessibilityLabel(index)}
                    isConfirm={isSelected}
                    title={isSelected ? 'Confirm Time Slot' : 'Select Time Slot'}
                />
            </StyledButtonContainer>
        </TimeSelectContainer>
    );
};

const mapStateToProps = (state) => ({
    cart: state.cart
});

export default connect(mapStateToProps)(FulfillmentTimeSelect);
