import {CmsAdType} from '../types';

export const defaultAds = {
    [CmsAdType.SLOT_1]: {
        mobile: {
            bannerId: 'grocery_home_one',
            imageUri:
                'https://088846e39aa1563dc3c4-d0a6918d306def8bc4fe6a1da9a06301.ssl.cf2.rackcdn.com/Secondary_Internal/Everyday/H-Plus_300x100.jpg',
            mainHref: 'https://www.hy-vee.com/plus',
            title: 'Hy-Vee Plus Membership'
        },
        web: {
            bannerId: 'grocery_home_mobile_one',
            imageUri:
                'https://088846e39aa1563dc3c4-d0a6918d306def8bc4fe6a1da9a06301.ssl.cf2.rackcdn.com/Secondary_Internal/Everyday/H-Plus_200x200.jpg',
            mainHref: 'https://www.hy-vee.com/plus',
            title: 'Hy-Vee Plus Membership'
        }
    },
    [CmsAdType.SLOT_2]: {
        mobile: {
            bannerId: 'grocery_home_two',
            imageUri:
                'https://088846e39aa1563dc3c4-d0a6918d306def8bc4fe6a1da9a06301.ssl.cf2.rackcdn.com/Secondary_Internal/Everyday/OneStep_300x100.jpg',
            mainHref: 'https://www.hy-vee.com/grocery/AD3753/P/One-Step',
            title: 'One Step'
        },
        web: {
            bannerId: 'grocery_home_mobile_two',
            imageUri:
                'https://088846e39aa1563dc3c4-d0a6918d306def8bc4fe6a1da9a06301.ssl.cf2.rackcdn.com/Secondary_Internal/Everyday/OneStep_200x200.jpg',
            mainHref: 'https://www.hy-vee.com/grocery/AD3753/P/One-Step',
            title: 'One Step'
        }
    },
    [CmsAdType.SLOT_3]: {
        mobile: {
            bannerId: 'grocery_home_three',
            imageUri:
                'https://088846e39aa1563dc3c4-d0a6918d306def8bc4fe6a1da9a06301.ssl.cf2.rackcdn.com/Secondary_Internal/Everyday/ExpressPickup_300x100.jpg',
            mainHref: 'https://www.hy-vee.com/grocery/AD2718/P/Basket-Starters',
            title: 'Express Pickup'
        },
        web: {
            bannerId: 'grocery_home_mobile_three',
            imageUri:
                'https://088846e39aa1563dc3c4-d0a6918d306def8bc4fe6a1da9a06301.ssl.cf2.rackcdn.com/Secondary_Internal/Everyday/ExpressPickup_200x200.jpg',
            mainHref: 'https://www.hy-vee.com/grocery/AD2718/P/Basket-Starters',
            title: 'Express Pickup'
        }
    },
    [CmsAdType.SLOT_4]: {
        mobile: {
            bannerId: 'grocery_home_four',
            imageUri:
                'https://088846e39aa1563dc3c4-d0a6918d306def8bc4fe6a1da9a06301.ssl.cf2.rackcdn.com/Secondary_Internal/Everyday/FullCircle2_300x100.jpg',
            mainHref: 'https://www.hy-vee.com/grocery/AD3551/P/Full-Circle',
            title: 'Full Circle – Clean Label for Clean Living'
        },
        web: {
            bannerId: 'grocery_home_mobile_four',
            imageUri:
                'https://088846e39aa1563dc3c4-d0a6918d306def8bc4fe6a1da9a06301.ssl.cf2.rackcdn.com/Secondary_Internal/Everyday/FullCircle2_200x200.jpg',
            mainHref: 'https://www.hy-vee.com/grocery/AD3551/P/Full-Circle',
            title: 'Full Circle – Clean Label for Clean Living'
        }
    },
    [CmsAdType.SLOT_5]: {
        mobile: {
            bannerId: 'grocery_home_five',
            imageUri:
                'https://088846e39aa1563dc3c4-d0a6918d306def8bc4fe6a1da9a06301.ssl.cf2.rackcdn.com/Secondary_Internal/Everyday/Overjoy_300x100.jpg',
            mainHref: 'https://www.hy-vee.com/grocery/AD6486/P/Overjoy-Ice-Cream',
            title: 'Overjoy'
        },
        web: {
            bannerId: 'grocery_home_mobile_five',
            imageUri:
                'https://088846e39aa1563dc3c4-d0a6918d306def8bc4fe6a1da9a06301.ssl.cf2.rackcdn.com/Secondary_Internal/Everyday/Overjoy_200x200.jpg',
            mainHref: 'https://www.hy-vee.com/grocery/AD6486/P/Overjoy-Ice-Cream',
            title: 'Overjoy'
        }
    },
    [CmsAdType.SLOT_6]: {
        mobile: {
            bannerId: 'grocery_home_six',
            imageUri:
                'https://088846e39aa1563dc3c4-d0a6918d306def8bc4fe6a1da9a06301.ssl.cf2.rackcdn.com/Secondary_Internal/Everyday/HSTV2_300x100.jpg',
            mainHref: 'https://hstv.com/',
            title: 'HSTV'
        },
        web: {
            bannerId: 'grocery_home_mobile_six',
            imageUri:
                'https://088846e39aa1563dc3c4-d0a6918d306def8bc4fe6a1da9a06301.ssl.cf2.rackcdn.com/Secondary_Internal/Everyday/HSTV2_200x200.jpg',
            mainHref: 'https://hstv.com/',
            title: 'HSTV'
        }
    },
    [CmsAdType.SLOT_7]: {
        mobile: {
            bannerId: 'grocery_home_seven',
            imageUri:
                'https://088846e39aa1563dc3c4-d0a6918d306def8bc4fe6a1da9a06301.ssl.cf2.rackcdn.com/Secondary_Internal/Everyday/ThatsSmart_300x100.jpg',
            mainHref: 'https://www.hy-vee.com/grocery/AD1796/P/Thats-Smart',
            title: 'That’s Smart'
        },
        web: {
            bannerId: 'grocery_home_mobile_seven',
            imageUri:
                'https://088846e39aa1563dc3c4-d0a6918d306def8bc4fe6a1da9a06301.ssl.cf2.rackcdn.com/Secondary_Internal/Everyday/ThatsSmart_200x200.jpg',
            mainHref: 'https://www.hy-vee.com/grocery/AD1796/P/Thats-Smart',
            title: 'That’s Smart'
        }
    },
    [CmsAdType.SLOT_8]: {
        mobile: {
            bannerId: 'grocery_home_eight',
            imageUri:
                'https://088846e39aa1563dc3c4-d0a6918d306def8bc4fe6a1da9a06301.ssl.cf2.rackcdn.com/Secondary_Internal/Everyday/Welcome_300x100.jpg',
            mainHref: 'https://www.hy-vee.com/grocery/AD2718/P/Basket-Starters',
            title: 'Welcome Promo'
        },
        web: {
            bannerId: 'grocery_home_mobile_eight',
            imageUri:
                'https://088846e39aa1563dc3c4-d0a6918d306def8bc4fe6a1da9a06301.ssl.cf2.rackcdn.com/Secondary_Internal/Everyday/Welcome_200x200.jpg',
            mainHref: 'https://www.hy-vee.com/grocery/AD2718/P/Basket-Starters',
            title: 'Welcome Promo'
        }
    }
};
