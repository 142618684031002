import {gql} from '@apollo/client';

export const createEcommerceClickEventMutation = gql`
    mutation CreateEcommerceClickEventMutation($vendorAdId: String!, $cartId: Int) {
        createEcommerceClickEvent(vendorAdId: $vendorAdId, cartId: $cartId) {
            messageId
        }
    }
`;

export const createEcommerceImpressionEventMutation = gql`
    mutation CreateEcommerceImpressionEventMutation($vendorAdId: String!, $cartId: Int) {
        createEcommerceImpressionEvent(vendorAdId: $vendorAdId, cartId: $cartId) {
            messageId
        }
    }
`;
