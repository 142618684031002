export const getRandom = <T>(arr: T[] | null | undefined): T | null => {
    if (arr && arr.length) {
        return arr[Math.floor(Math.random() * arr.length)];
    }

    return null;
};

const range = (size) => {
    return [...new Array(size)].map((e, i) => i);
};

export const shuffle = <T>(arr: T[] | null | undefined): T[] => {
    if (!arr) {
        return [];
    }

    const newArray: T[] = [],
        length = Number(arr.length),
        indexes = range(length);

    let temp = 0,
        lastSourceIdx = length - 1,
        swapIdx;

    for (let idx = 0; idx < length; idx++) {
        swapIdx = Math.floor(Math.random() * lastSourceIdx);
        temp = indexes[swapIdx];

        newArray[idx] = arr[temp];

        indexes[swapIdx] = indexes[lastSourceIdx];
        lastSourceIdx -= 1;
    }

    return newArray;
};

export const notEmpty = <TValue>(value: TValue | null | undefined): value is TValue => {
    return value !== null && value !== undefined;
};
