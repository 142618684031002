enum SwimlaneTypes {
    BUY_AGAIN = 'BUY_AGAIN',
    FEATURED_COLLECTION = 'FEATURED_COLLECTION',
    FEATURED_COLLECTION_1 = 'FEATURED_COLLECTION_1',
    FEATURED_COLLECTION_2 = 'FEATURED_COLLECTION_2',
    FEATURED_COLLECTION_3 = 'FEATURED_COLLECTION_3',
    FEATURED_COLLECTION_4 = 'FEATURED_COLLECTION_4',
    FUEL_SAVER = 'FUEL_SAVER',
    ON_SALE = 'ON_SALE',
    PREDICTIONS = 'PREDICTIONS',
    RECOMMENDATION = 'RECOMMENDATION',
    POPULAR_PRODUCTS = 'POPULAR_PRODUCTS'
}

export default SwimlaneTypes;
