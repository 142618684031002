import Alert from '@hy-vee/web-core/lib/components/alert';
import styled from 'styled-components';

import {TIME_RESERVATION_SLOT_CONFLICT} from '../../../enums/error-types';
import {getFormattedTime} from '../../../utils/view-helpers/date-time-view-helpers';
import AlertStatusType from '../../../enums/alert-status-types';

const NewLinesContainer = styled.div`
    * {
        display: block;
    }
`;

const getErrorMessage = (errorType, fulfillmentTime) => {
    const windowStart = getFormattedTime(fulfillmentTime.windowStart);
    const windowEnd = getFormattedTime(fulfillmentTime.windowEnd);

    if (errorType === TIME_RESERVATION_SLOT_CONFLICT) {
        return `We're sorry, but ${windowStart} - ${windowEnd} has reached its capacity and is no longer available.`;
    }

    return `We're sorry, but ${windowStart} - ${windowEnd} is no longer available. Please select another time.`;
};

const FulfillmentTimeError = ({errorType, fulfillmentTime}) => (
    <dl role="alert">
        <Alert status={AlertStatusType.ERROR_ALERT}>
            <NewLinesContainer>
                <strong>{'Please Select Another Time'}</strong>
                <span>{getErrorMessage(errorType, fulfillmentTime)}</span>
            </NewLinesContainer>
        </Alert>
    </dl>
);

export default FulfillmentTimeError;
